.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Hide Arrows From Input Number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/*IBMPlexMono*/
@font-face {
  font-family: 'IBMPlexMono-Regular';
  src: local('IBMPlexMono-Regular'), url(../assets/fonts/IBMPlexMono/IBMPlexMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Medium';
  src: local('IBMPlexMono-Medium'), url(../assets/fonts/IBMPlexMono/IBMPlexMono-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-SemiBold';
  src: local('IBMPlexMono-SemiBold'), url(../assets/fonts/IBMPlexMono/IBMPlexMono-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Bold';
  src: local('IBMPlexMono-Bold'), url(../assets/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf) format('truetype');
}

/*SFUIDisplay*/
@font-face {
  font-family: 'SFUIDisplay-Ultralight';
  src: local('SFUIDisplay-Ultralight'), url(../assets/fonts/SFDisplay/SFUIDisplay-Ultralight.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Light';
  src: local('SFUIDisplay-Light'), url(../assets/fonts/SFDisplay/SFUIDisplay-Light.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Thin';
  src: local('SFUIDisplay-Thin'), url(../assets/fonts/SFDisplay/SFUIDisplay-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Medium';
  src: local('SFUIDisplay-Medium'), url(../assets/fonts/SFDisplay/SFUIDisplay-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Semibold';
  src: local('SFUIDisplay-Semibold'), url(../assets/fonts/SFDisplay/SFUIDisplay-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Bold';
  src: local('SFUIDisplay-Bold'), url(../assets/fonts/SFDisplay/SFUIDisplay-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Heavy';
  src: local('SFUIDisplay-Heavy'), url(../assets/fonts/SFDisplay/SFUIDisplay-Heavy.otf) format('opentype');
}

@font-face {
  font-family: 'SFUIDisplay-Black';
  src: local('SFUIDisplay-Black'), url(../assets/fonts/SFDisplay/SFUIDisplay-Black.otf) format('opentype');
}

/*OpenSans*/
@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(../assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'), url(../assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(../assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}

